import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/react';
import { traceIdHeader } from 'folio-common-utils';
import { idGenerator } from 'folio-tracing-web';
import { print } from 'graphql';
import {
  ConfirmSessionDocument,
  type ConfirmSessionMutation,
  type ConfirmSessionMutationVariables,
  GeneratePreviewDocument,
  type GeneratePreviewMutationVariables,
  LoadAppStateDocument,
  type LoadAppStateQuery,
  type LoadAppStateQueryVariables,
  PollFoundingStateDocument,
  type PollFoundingStateQueryVariables,
  ReopenSessionDocument,
  type ReopenSessionMutationVariables,
  SaveEditorStateDocument,
  type SaveEditorStateMutationVariables,
  SendMailDocument,
  type SendMailMutationVariables,
  SetAccountingSelectionDocument,
  type SetAccountingSelectionMutationVariables,
  SetBankSelectionDocument,
  type SetBankSelectionMutationVariables,
} from '../gqltypes';
import { mountPath } from '../paths';
import type { SearchByNameVariables } from './generated-types/SearchByName';
import type { SearchByOrgIdVariables } from './generated-types/SearchByOrgId';
import type { ValidCompanyNameVariables } from './generated-types/ValidCompanyName';

interface GqlResponse<T> {
  data: T;
  errors?: { message: string; path: string[] }[];
}

type GraphqlFetchOptions<Variables> = {
  operationName: string;
  variables: Variables;
};

function graphqlFetch<Query, Variables>(
  query: TypedDocumentNode<Query, Variables>,
  { operationName, variables }: GraphqlFetchOptions<Variables>,
): Promise<GqlResponse<Query>> {
  const span = trace.getActiveSpan();
  const traceId = span?.spanContext().traceId ?? idGenerator.generateTraceId();

  Sentry.configureScope(scope => scope.setTag('trace-id', traceId));

  const printedQuery = print(query);

  if (span) {
    span.setAttribute('query', printedQuery);
    if (operationName) {
      span.setAttribute('operationName', operationName);
    }
  }

  Sentry.addBreadcrumb({
    category: 'graphql-request',
    type: 'http',
    data: {
      query: printedQuery,
      operationName,
      variables,
    },
  });

  return fetch(`${mountPath}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      operationName,
      query: printedQuery,
      variables,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Operation-Name': operationName,
      [traceIdHeader]: traceId,
    },
    credentials: 'same-origin',
  }).then(e => {
    if (!e.ok) {
      throw new Error(
        `Unable to fetch graphql endpoint, got status ${e.status}"`,
      );
    }
    return e.json();
  });
}

/**
 * The queries will use fragments for the queries, but gql2ts doesn't currently
 * support generating types definitions for fragments.
 * See https://github.com/avantcredit/gql2ts/issues/163
 */

export async function saveEditorState(
  variables: SaveEditorStateMutationVariables,
): Promise<void> {
  const body = await graphqlFetch(SaveEditorStateDocument, {
    operationName: 'SaveEditorState',
    variables,
  });

  if (!body.data.maSave) {
    throw new Error(`Could not save "${variables.id}"`);
  } else {
    return undefined;
  }
}

type LoadAppStateResponse = {
  body?: Record<string, unknown>;
  founding?: LoadAppStateQuery['maFounding'];
  brregProcessingTime: number | null;
};

export async function loadAppState(
  variables: LoadAppStateQueryVariables,
): Promise<LoadAppStateResponse> {
  const body = await graphqlFetch(LoadAppStateDocument, {
    operationName: 'LoadAppState',
    variables,
  });

  if (body.errors) {
    throw new Error(`Could not load "${variables.id}"`);
  } else {
    let editor;
    if (body.data.editorState) {
      editor = JSON.parse(body.data.editorState);
    }
    return {
      body: editor,
      founding: body.data.maFounding,
      brregProcessingTime: body.data.brregProcessingTime,
    };
  }
}

export async function reopen(variables: ReopenSessionMutationVariables) {
  const body = await graphqlFetch(ReopenSessionDocument, {
    operationName: 'ReopenSession',
    variables,
  });

  if (body.errors) {
    throw new Error('Could not reopen for editing');
  } else {
    return body;
  }
}

export async function confirmSession(
  variables: ConfirmSessionMutationVariables,
): Promise<ConfirmSessionMutation['maConfirm']> {
  const body = await graphqlFetch(ConfirmSessionDocument, {
    operationName: 'ConfirmSession',
    variables,
  });

  if (!body.data.maConfirm || body.errors) {
    throw new Error(`Could not confirm "${variables.founding.id}"`);
  } else {
    return body.data.maConfirm;
  }
}

export async function generatePreviewDocumentUrl(
  variables: GeneratePreviewMutationVariables,
) {
  const body = await graphqlFetch(GeneratePreviewDocument, {
    operationName: 'GeneratePreview',
    variables,
  });
  return body.data.maGeneratePreview;
}

export async function searchByOrgNum(variables: SearchByOrgIdVariables) {
  const res = await fetch(`${mountPath}/orgnum-search?orgnum=${variables.id}`);

  if (res.ok) {
    try {
      const items = await res.json();
      return {
        term: variables.id,
        items,
      };
    } catch {
      /* ignore */
    }
  }

  return {
    term: variables.id,
    items: [],
  };
}

export async function searchByName(variables: SearchByNameVariables) {
  const res = await fetch(
    `${mountPath}/name-search?name=${encodeURIComponent(variables.name)}`,
  );

  if (res.ok) {
    try {
      const items = await res.json();
      return {
        term: variables.name,
        items,
      };
    } catch {
      /* ignore */
    }
  }

  return {
    term: variables.name,
    items: [],
  };
}

export async function isAvailableCompanyName(
  variables: ValidCompanyNameVariables,
) {
  const res = await fetch(
    `${mountPath}/is-available-company-name?name=${encodeURIComponent(
      variables.name,
    )}`,
  );

  if (res.ok) {
    try {
      const json = await res.json();
      return json.success;
    } catch {
      /* ignore */
    }
  }

  return true;
}

export async function submitEmail(variables: SendMailMutationVariables) {
  const body = await graphqlFetch(SendMailDocument, {
    operationName: 'SendMail',
    variables,
  });

  if (body.data.maSendMail !== undefined) {
    return body.data.maSendMail;
  } else {
    throw new Error('Unable to submit mail');
  }
}

export async function pollFoundingState(
  variables: PollFoundingStateQueryVariables,
) {
  const body = await graphqlFetch(PollFoundingStateDocument, {
    operationName: 'PollFoundingState',
    variables,
  });

  if (body.data.maFounding !== undefined) {
    return {
      founding: body.data.maFounding,
      brregProcessingTime: body.data.brregProcessingTime,
    };
  } else {
    throw new Error('Unable to submit mail');
  }
}

export async function setBankSelection(
  variables: SetBankSelectionMutationVariables,
) {
  const body = await graphqlFetch(SetBankSelectionDocument, {
    operationName: 'SetBankSelection',
    variables,
  });

  if (body.data.maSetBankSelection !== undefined) {
    return body.data.maSetBankSelection;
  } else {
    throw new Error('Unable to submit mail');
  }
}

export async function setAccountingSelection(
  variables: SetAccountingSelectionMutationVariables,
) {
  const body = await graphqlFetch(SetAccountingSelectionDocument, {
    operationName: 'SetAccountingSelection',
    variables,
  });

  if (body.data.maSetAccountingSelection !== undefined) {
    return body.data.maSetAccountingSelection;
  } else {
    throw new Error('Unable to submit mail');
  }
}
